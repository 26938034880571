
<template>
  <Layout>
    <div class="float-right mb-4">
      <b-button class="bseen-btn" @click="showActionModal">Add</b-button>
    </div>
    <div class="clearfix"></div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="roleData"
                :fields="fields"
                responsive="sm"
                :busy="isBusy"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(actions)="row">
                  <button class="action-btn" @click="deleteRole(row.item)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <button class="action-btn" @click="editRole(row.item)">
                    <i class="fas fa-edit"></i>
                  </button>
                </template>
                <template #cell(permissions)="row">
                  {{ row.item.permissions.length || 0 }} permissions
                </template>
                
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RoleModal
      :selectedRole="selectedRole"
      v-on:resetModal="selectedRole = null"
      v-on:reloadData="getAllRole"
      :permissionData="permissionData"
    />
    <RoleUpdateModal
      :data="selectedData"
      v-on:resetModal="selectedData = null"
      v-on:reloadData="getAllRole"
    />
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import RoleModal from "@/components/users/roles-modal";
import RoleUpdateModal from "@/components/users/roles-update-modal";
import { roles } from "@/config/api/roles";
import { permissions } from "@/config/api/permissions";

export default {
  components: { Layout, RoleModal, RoleUpdateModal },
  data() {
    return {
      roleData: [],
      selectedRole: null,
      permissionData: [],
      userRoles: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectedData: null,
      fields: [
        { key: "name", sortable: false },
        { key: "permissions", sortable: false },
        { key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.roleData.length;
    },
  },
  mounted() {},
  created() {
    this.getAllRole();
    this.getAllPermission();
  },
  methods: {
    getAllPermission() {
      const api = permissions.get;
      this.generateAPI(api)
        .then((res) => {
          console.log("Prmissions", res.data);
          this.permissionData = res.data.permissions;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllRole() {
      this.isBusy = true;
      const api = roles.get;
      this.generateAPI(api)
        .then((res) => {
          this.roleData = res.data.roles;
          this.totalRows = this.roleData.length;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    showActionModal() {
      this.$bvModal.show("action-Role");
    },
    updateModal() {
      this.$bvModal.show("update-Role");
    },

    editRole(data) {
      if (this.permissionData.length) {
        this.selectedData = {
          selectedRole: data,
          permissions: this.permissionData,
        };
        this.updateModal();
      } else {
        return;
      }
    },
    deleteRole(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = roles.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then((res) => {
                console.log(res.data);
                this.$bvToast.toast("Role deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.getAllRole();
              })
              .catch((err) => {
                this.$bvToast.toast("Role is not deleted!", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
          // An error occurred
        });
    },
  },
};
</script>
