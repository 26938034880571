export const permissions={
    create:{
        url:'permission/add',
        method:'POST',
        data:null
    },
    update:{
        url:'permission/update/',
        method:'PUT',
        id:null,
        data:null
    },
    get:{
        url:'permission/get',
        method:'GET',
        id:null,
        params:null
    },
    delete:{
        url:'permission/delete/',
        method:'DELETE',
        id:null
    }
}
